//
//  hks_template_twbs4.scss
//

//
//  Fonts
//

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("../Fonts/roboto-v18-latin-ext_latin-300.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("../Fonts/roboto-v18-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-v18-latin-ext_latin-300.woff2") format("woff2"), url("../Fonts/roboto-v18-latin-ext_latin-300.woff") format("woff"), url("../Fonts/roboto-v18-latin-ext_latin-300.ttf") format("truetype"), url("../Fonts/roboto-v18-latin-ext_latin-300.svg#Roboto") format("svg");
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/roboto-v18-latin-ext_latin-regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("../Fonts/roboto-v18-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-v18-latin-ext_latin-regular.woff2") format("woff2"), url("../Fonts/roboto-v18-latin-ext_latin-regular.woff") format("woff"), url("../Fonts/roboto-v18-latin-ext_latin-regular.ttf") format("truetype"), url("../Fonts/roboto-v18-latin-ext_latin-regular.svg#Roboto") format("svg");
}

/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../Fonts/roboto-v18-latin-ext_latin-500.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../Fonts/roboto-v18-latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-v18-latin-ext_latin-500.woff2") format("woff2"), url("../Fonts/roboto-v18-latin-ext_latin-500.woff") format("woff"), url("../Fonts/roboto-v18-latin-ext_latin-500.ttf") format("truetype"), url("../Fonts/roboto-v18-latin-ext_latin-500.svg#Roboto") format("svg");
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/roboto-v18-latin-ext_latin-700.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../Fonts/roboto-v18-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-v18-latin-ext_latin-700.woff2") format("woff2"), url("../Fonts/roboto-v18-latin-ext_latin-700.woff") format("woff"), url("../Fonts/roboto-v18-latin-ext_latin-700.ttf") format("truetype"), url("../Fonts/roboto-v18-latin-ext_latin-700.svg#Roboto") format("svg");
}

/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url("../Fonts/roboto-v18-latin-ext_latin-900.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("../Fonts/roboto-v18-latin-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-v18-latin-ext_latin-900.woff2") format("woff2"), url("../Fonts/roboto-v18-latin-ext_latin-900.woff") format("woff"), url("../Fonts/roboto-v18-latin-ext_latin-900.ttf") format("truetype"), url("../Fonts/roboto-v18-latin-ext_latin-900.svg#Roboto") format("svg");
}

/* roboto-condensed-300 - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("../Fonts/roboto-condensed-v16-latin-300.eot");
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url("../Fonts/roboto-condensed-v16-latin-300.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-condensed-v16-latin-300.woff2") format("woff2"), url("../Fonts/roboto-condensed-v16-latin-300.woff") format("woff"), url("../Fonts/roboto-condensed-v16-latin-300.ttf") format("truetype"), url("../Fonts/roboto-condensed-v16-latin-300.svg#RobotoCondensed") format("svg");
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/roboto-condensed-v16-latin-regular.eot");
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("../Fonts/roboto-condensed-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-condensed-v16-latin-regular.woff2") format("woff2"), url("../Fonts/roboto-condensed-v16-latin-regular.woff") format("woff"), url("../Fonts/roboto-condensed-v16-latin-regular.ttf") format("truetype"), url("../Fonts/roboto-condensed-v16-latin-regular.svg#RobotoCondensed") format("svg");
}

/* roboto-condensed-700 - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/roboto-condensed-v16-latin-700.eot");
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url("../Fonts/roboto-condensed-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("../Fonts/roboto-condensed-v16-latin-700.woff2") format("woff2"), url("../Fonts/roboto-condensed-v16-latin-700.woff") format("woff"), url("../Fonts/roboto-condensed-v16-latin-700.ttf") format("truetype"), url("../Fonts/roboto-condensed-v16-latin-700.svg#RobotoCondensed") format("svg");
}

//
//  Elements
//
html {
  position: relative;
  min-height: 100%;
}
body {
  hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3; // 10 4 4
  -moz-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 3;
  -moz-hyphenate-limit-lines: 3;
  -ms-hyphenate-limit-lines: 3;
  hyphenate-limit-lines: 3;
  -webkit-hyphenate-limit-last: always;
  -moz-hyphenate-limit-last: always;
  -ms-hyphenate-limit-last: always;
  hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;
  -moz-hyphenate-limit-zone: 8%;
  -ms-hyphenate-limit-zone: 8%;
  hyphenate-limit-zone: 8%;
}

html,
body {
  position: relative;
  overflow-x: hidden;
  @include media-breakpoint-up(xl) {
    overflow-x: visible;
  }
}

a {
  color: $tourquoise;
  &:hover {
    color: $tourquoise;
    transition: all .2s ease-in-out;
  }
  h1 &,
  .h1 &,
  h2 &,
  .h2 &,
  h3 &,
  .h3 &,
  h4 &,
  .h4 &,
  h5 &,
  .h5 & {
    line-height: 1.1;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: $font-family-condensed;
  font-weight: 700;
  hyphens: manual;
  text-transform: uppercase;
}

h2,
.h2 {
  font-size: 1.8rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }
}
h3,
.h3 {
  font-size: 1.3rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
}
h4,
.h4 {
  font-size: 1rem;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: .8rem;
  @include media-breakpoint-up(sm) {
    font-size: .9rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

h1.line-trough,
h2.line-trough,
h3.line-trough,
h4.line-trough,
h5.line-trough,
h2.hero {
  display: table;
  white-space: nowrap;
  &::before,
  &::after {
    position: relative;
    top: .5em;
    display: table-cell;
    width: 45%;
    content: "";
    border-top: 1px solid $gray;
  }
  &::before { right: 1.5%; }
  &::after { left: 1.5%; }
  @include media-breakpoint-down(md) {
    text-align: center;
    white-space: normal;
    &::before,
    &::after {
      display: none;
    }
  }
}

h1.line-below,
h2.line-below,
h3.line-below,
h4.line-below,
h5.line-below {
  position: relative;
  &::after {
    position: relative;
    bottom: 0;
    display: block;
    width: 15%;
    margin: 3rem 0;
    content: "";
    border-top: 1px solid $dark;
  }
}

h2.line-below::after {
  margin: 3rem 0;

  @include media-breakpoint-down(lg) {
    margin: 3rem 0;
  }
  @include media-breakpoint-down(md) {
    margin: 2rem 0;
  }
  @include media-breakpoint-down(sm) {
    margin: 1.75rem 0;
  }
  @include media-breakpoint-down(xs) {
    margin: 1.5rem 0;
  }
}
h3.line-below::after {
  margin: 2.5rem 0;

  @include media-breakpoint-down(lg) {
    margin: 2.5rem 0;
  }
  @include media-breakpoint-down(md) {
    margin: 2rem 0;
  }
  @include media-breakpoint-down(sm) {
    margin: 1.5rem 0;
  }
  @include media-breakpoint-down(xs) {
    margin: 1rem 0;
  }
}
h4.line-below::after {
  margin: 2rem 0;

  @include media-breakpoint-down(lg) {
    margin: 2rem 0;
  }
  @include media-breakpoint-down(md) {
    margin: 1.5rem 0;
  }
  @include media-breakpoint-down(sm) {
    margin: 1rem 0;
  }
  @include media-breakpoint-down(xs) {
    margin: .75rem 0;
  }
}
h5.line-below::after {
  margin: 1.5rem 0;

  @include media-breakpoint-down(lg) {
    margin: 1.5rem 0;
  }
  @include media-breakpoint-down(md) {
    margin: 1.25rem 0;
  }
  @include media-breakpoint-down(sm) {
    margin: .75rem 0;
  }
  @include media-breakpoint-down(xs) {
    margin: .75rem 0;
  }
}

footer {
  padding-bottom: 72px;
  background-color: #1b244c;
  a {
    color: $secondary;
  }
  img {
    margin: 5px 10px 7px 0;
    fill: $secondary;
  }
  .listenkopf {
    margin-bottom: 0;
  }
  .col-md .rand {
    @include media-breakpoint-up(md) {
      height: 80%;
      padding-left: 25px;
      margin-top: 40px;
      margin-left: -26px;
      border-left: 1px dotted $secondary;
    }
  }
  .col-lg .rand {
    @include media-breakpoint-up(lg) {
      height: 80%;
      padding-left: 25px;
      margin-top: 40px;
      margin-left: -26px;
      border-left: 1px dotted $secondary;
    }
  }
}

.nav-footer {
  font-weight: 700;
}
.nav-footer--child {
  padding-left: 16px;
  margin: .5rem 0 .75rem;
  list-style-type: disc;

  a {
    font-weight: 100;
  }
}

//
//  IDs and Classes
//
.outline {
  outline: 0 dotted $red;
  @include media-breakpoint-up(sm) {
    padding-right: 0;
    padding-left: 0;
  }
}

.ce-bodytext,
.frame-type-text,
.frame-type-textmedia {
  ul {
    margin-left: -1.5rem;
  }
}

.frame-posvertical,
.posvertical {
  padding-top: 3rem;
  padding-bottom: 3rem;
  [class*="col-"] > .negvertical:first-child,
  [class*="col-"] > .trenner > .negvertical:first-child,
  [class*="col-"] > .breit > .negvertical:first-child {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
}

.pt-0 .posvertical {
  padding-top: 0;
}
.pb-0 .posvertical {
  padding-bottom: 0;
}

.bg-gray.iconnav .posvertical {
  padding-bottom: 0;
}

.flex-equal {
  flex: 1;
}

.w-15 {
  width: 15%;
}

.mb-30 {
  margin-bottom: 30px;
}

.columns-2 {
  columns: 150px 2;
  column-gap: 2rem;
}

.bg-primary {
  background-color: $primary;
}
.bg-secondary {
  background-color: $secondary;
}

.bg-orange {
  position: relative;
  background-color: $orange;
}
.text-orange {
  color: $orange;
}

.bg-tq {
  position: relative;
  background-color: $tourquoise;
}
.text-tq {
  color: $tourquoise;
}

.bg-blue {
  position: relative;
  background-color: $blue;
}
.text-blue {
  color: $blue;
}

.bg-black {
  position: relative;
  background-color: $black;
}
.text-black {
  color: $black;
}

.bg-gray-stone {
  position: relative;
  background-color: $gray-stone;
}
.text-gray-stone {
  color: $gray-stone;
}

.border-gray-dark {
  position: relative;
  border-color: $gray-dark;
}
.bg-gray-dark {
  position: relative;
  background-color: $gray-dark;
}
.text-gray-dark {
  color: $gray-dark;
}

.border-gray-medium {
  border-color: $gray-medium;
}
.bg-gray-medium {
  position: relative;
  background-color: $gray-medium;
}
.text-gray-medium {
  color: $gray-medium;
}

.bg-gray {
  position: relative;
  background-color: $gray;
}
.text-gray {
  color: $gray;
}

.bg-gray-light {
  position: relative;
  background-color: $gray-light;
}
.text-gray-light {
  color: $gray-light;
}

.bg-white {
  position: relative;
  background-color: $white;
}
.text-white {
  color: $white;
}

@media (min-width: 1399px) {
  .bg-gray,
  .bg-gray-light:not(.topics) {
    &::after {
      position: absolute;
      top: 0;
      right: 50%;
      left: 50%;
      z-index: -1;
      width: 99.5%;
      width: 99.5vw;
      height: 100%;
      margin-right: -50vw;
      margin-left: -50vw;
      content: "";
      background-color: inherit;
    }
  }
}

.downloadbox {
  .btn-boss {
    box-shadow: none;
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-width: 265px;
    .btn-boss {
      width: 100%;
    }
  }
}

.bg-gray.downloadbox::after,
.bg-gray.iconnav::after {
  display: none;
}

.bg-product {
  padding: .8rem;
  background-color: $gray;
  opacity: .8;
}

.breit {
  .content {
    max-width: 450px;
  }
  .bild {
    flex: 1 1 auto;
    width: 25%;
  }
}

.trenner > [class*="col-"]::before,
.trenner > [class*="col-"]::after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  // content: "";
  background: none;
  @include media-breakpoint-up(lg) {
    // background: $gray;
  }
}
.trenner > [class*="col-"] {
  padding-right: 19px;
  padding-left: 20px;
  border-right: 0;
  @include media-breakpoint-up(lg) {
    border-right: 1px solid $gray;
  }
}
.trenner > [class*="col-"]::before {
  left: 0;
}
.trenner > [class*="col-"]::after {
  right: -1px;
}
.trenner > [class*="col-"]:last-child {
  border-right: 0;
}
.trenner > [class*="col-"]:first-child::before,
.trenner > [class*="col-"]:last-child::after {
  display: none;
}

.responsive-video {
  position: relative;
  height: 0;
  padding-top: 15px;
  padding-bottom: 55%;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.pfeil {
  &::after {
    display: inline-block;
    width: 35px;
    height: 15px;
    margin-left: 1rem;
    line-height: 0;
    vertical-align: middle;
    content: "";
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 15"><style></style><g fill="none" stroke="%235bc0cc" stroke-linecap="round"><path d="M2.83 7.5h28.84"/><path stroke-linejoin="round" d="M25.31 13.29l6.36-5.79-6.36-5.79"/></g></svg>');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 30px 13px;
    -webkit-transition: margin .3s ease;
    -o-transition: margin .3s ease;
    transition: margin .3s ease;
  }
  &:hover::after {
    margin-left: 1.5rem;
    -webkit-transition: margin .3s ease;
    -o-transition: margin .3s ease;
    transition: margin .3s ease;
  }
  img {
    -webkit-transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
  }
  &:hover img {
    -webkit-transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.xyz.pfeil::before {
  display: inline-block;
  width: 35px;
  height: 30px;
  margin-right: 1rem;
  line-height: 0;
  vertical-align: middle;
  content: "";
  background-image: url("../Icons/BOSS_Icon_Einzelhandel.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30px 30px;
}

.navbar {
  min-height: 54px;
  padding: 0;
  font-size: .8rem;
  .navbar-brand {
    display: inline-block;
    width: 150px;
    padding: 0 0 0 10px;
    margin: 0;
    img {
      width: 150px;
      height: 68px;
    }
  }
  .icons {
    height: 68px;
    @include media-breakpoint-up(sm) {
      height: 80px;
    }
    .nav-item {
      border-bottom: 0;
    }
  }
  .navbar-nav {
    text-transform: uppercase;
    .nav-item {
      .nav-link {
        @include media-breakpoint-up(xl) {
          display: inline-block;
          padding: 41px .8rem 20px;
          &.topnav-icon {
            padding: 39px 16px 19px;
          }
        }
      }
      .nav-item--top a:hover {
        background-color: $tourquoise;
      }
    }
    .nav-link.topnav-icon {
      padding: 23px 16px;
      margin-bottom: -3px;
      @include media-breakpoint-up(sm) {
        padding: 29px 16px;
      }
    }
    &.icons {
      flex-direction: row;
      @media (min-width: 1450px) {
        margin-left: auto !important;
      }
    }
  }
  .navbar-collapse {
    top: 80px;
    align-items: flex-start;
    padding: 0;
  }
  .main {
    border-bottom: 1px dotted $primary;
    @include media-breakpoint-up(xl) {
      border-bottom: 0;
    }
    .active {
      padding: 0 10px;
    }

    .nav-item:hover {
      & > a {
        background-color: $tourquoise;
      }
    }
  }
  .language-menu {
    position: relative;
    .dropdown-menu {
      display: none;
      min-width: 54px;
      padding: 0;
      margin: 0;
      text-align: center;
      border-color: $tourquoise;
      border-radius: 0;
      box-shadow: 2px 5px 6px 0 rgba($black, .25);
      @include media-breakpoint-up(xl) {
        border: 0;
        border-color: inherit;
      }
      .dropdown-item {
        padding: .25rem;
        border-radius: 0;
      }
      .active {
        background-color: $tourquoise;
      }
    }
    &:hover {
      .dropdown-menu {
        position: absolute;
        display: block;
      }
    }
    .dropdown-item:hover {
      background-color: $tourquoise;
    }
  }

  @media (min-width: 1200px) {
    .nav-item:hover {
      .nav {
        display: block;

        .nav-link {
          display: block;
          padding: 12px 1.2rem;
        }
      }
    }
  }

  .navbar-toggler {
    border-color: $white;
    border-radius: 0;
  }

  .nav {
    position: absolute;
    z-index: 10;
    display: none;
    background: $white;
    box-shadow: 0 0 8 0 rgba($black, .5);
  }
}

.nav-sub {
  .nav {
    display: block;
  }
}

.topnav {
  .outline {
    padding-right: 0;
    padding-left: 0;
  }
}


@media (min-width: 1199px) {
  .menuhome,
  .menuback {
    display: none;
  }
}
@media (max-width: 1199px) {
  .navbar-collapse.collapse:not(.show) {
    display: block;
  }


  .navbar-collapse {
    position: absolute;
    top: 54px;
    right: 0;
    bottom: 0;
    width: 70vw;
    height: 100vh;
    padding: 1rem 0;
    background: $white;
    box-shadow: 4px 22px 19px 0 rgba($black, .8);
    transition: all .5s ease;
    transform: translate(0, 0);

    .navbar-nav--main {
      margin-top: 0 !important;
    }

    .nav-link,
    .nav-link.active {
      padding: .8rem 1rem;
      // margin: 0 !important;
    }
  }

  .navbar-collapse:not(.show) {
    transition: all .5s ease;
    transform: translate(100vw, 0);
  }

  .nav-item {
    // display: flex;
    // justify-content: space-between;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid $tourquoise;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background-color: $tourquoise;
    }
    .nav-item .nav-link {
      padding: .8rem 1rem .8rem 1.5rem;
    }
    .nav-item .nav-link.third {
      padding: .8rem 1rem .8rem 2rem;
    }
  }

  .nav-item.active > .nav.flex-column {
    position: relative;
    // top: calc(2.8rem + 8px);
    // right: 0;
    // bottom: 0;
    z-index: 11;
    display: flex;
    // width: 70vw;
    // height: 100vh;
    // padding: 0;
    background: $white;
    transition: all .5s ease;
    // transform: translate(0, 0);
  }

  .nav.flex-column:not(.show) {
    transition: all .5s ease;
    // transform: translate(100vw, 0);
  }

  .deeper {
    z-index: 10;
    display: block;
    width: 25%;
    line-height: 3;
    text-align: center;
    cursor: pointer;
    background-color: $white;

    &.pfeil::after {
      width: 100%;
      height: 100%;
    }
  }

  .tiefer {
    background-color: $white;
    & > .nav-link .toggler,
    & > .nav-link .nav-item--top .nav-link .toggler {
      display: inline-block;
      float: right;
      width: 20px;
      height: 20px;
      @include media-breakpoint-up(sm) {
        margin-right: 33%;
        margin-right: 25%;
      }
      @include media-breakpoint-up(md) {
        margin-right: 49.1%;
        margin-right: 25%;
      }
      padding-left: 25%;
      line-height: 0;
      vertical-align: top;
      content: "";
      background-image: url("../Icons/BOSS_Pfeil_rechts_blau.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: 20px 20px;
    }
    &.active {
      background-color: $tourquoise;
      & .tiefer.active {
        background-color: inherit;
      }
      & > .nav-link .toggler,
      & > .nav-link .nav-item--top.active .toggler {
        background-image: url("../Icons/BOSS_Pfeil_unten_blau.svg");
      }
    }
  }

  .menuhome,
  .menuback {
    padding: 1rem 0;
    color: $blue;
    text-align: center;
    cursor: pointer;
    background: $tourquoise;
    border-top: 1px solid $tourquoise;
    border-bottom: 1px solid $tourquoise;
  }
}

.icon-navigation {
  display: none;
}

@include media-breakpoint-up(xl) {

  .icon-navigation {
    position: sticky;
    top: 80px;
    z-index: 9;
    display: block;
    overflow: hidden;
    background-color: $gray;
    box-shadow: 0 0 6px 0 rgba($black, .35);

    .nav-sub .nav-link {
      font-size: .75rem;
    }
  }

  .topnav {
    position: sticky;
    top: 0;
    z-index: 10;
    background: $white;
    box-shadow: 0 0 6px 0 rgba($black, .25);
  }

  .deeper,
  .menuback {
    display: none;
  }

  .nav-sub {
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    background-color: $gray;

    .nav {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .nav-item {
      width: 10%;
      #page-106 & {
        width: auto;
      }
      border-right: 1px solid $gray-medium;

      &:last-child {
        border: none;
      }
    }

    .nav-link {
      display: block;
      height: 100%;
      padding: 1rem;
      hyphens: none;
      text-align: center;

      &.active,
      &:hover {
        color: $tourquoise !important;
      }
    }
  }

  .nav-icon {
    &::before {
      display: none;
      width: 50px;
      height: 50px;
      margin: 0 auto .25rem;
      content: "";
      background-image: url("../Icons/BOSS_Zubehoer.svg");
      background-repeat: no-repeat;
      background-position: top center;
    }

    &.active::before,
    &:hover::before {
      background-image: url("../Icons/BOSS_Zubehoer_hover.svg");
    }
  }

  .nav-icon28::before {
    display: block;
    background-image: url("../Icons/BOSS_Tischmodelle.svg");
  }
  .nav-icon28.active::before,
  .nav-icon28:hover::before {
    display: block;
    background-image: url("../Icons/BOSS_Tischmodelle_hover.svg");
  }
  .nav-icon29::before {
    display: block;
    background-image: url("../Icons/BOSS_Standgeraete.svg");
  }
  .nav-icon29.active::before,
  .nav-icon29:hover::before {
    background-image: url("../Icons/BOSS_Standgeraete_hover.svg");
  }
  .nav-icon31::before {
    display: block;
    background-image: url("../Icons/BOSS_Doppelkammermaschienen.svg");
  }
  .nav-icon31.active::before,
  .nav-icon31:hover::before {
    background-image: url("../Icons/BOSS_Doppelkammermaschienen_hover.svg");
  }
  .nav-icon42::before,
  .nav-icon30::before {
    display: block;
    background-image: url("../Icons/BOSS_Zubehoer.svg");
  }
  .nav-icon41::before {
    display: block;
    background-image: url("../Icons/BOSS_SchrumpfTauchtanks.svg");
  }
  .nav-icon41.active::before,
  .nav-icon41:hover::before {
    background-image: url("../Icons/BOSS_SchrumpfTauchtanks_hover.svg");
  }
  .nav-icon33::before {
    display: block;
    background-image: url("../Icons/BOSS_Vertikale_Vakuummaschinen.svg");
  }
  .nav-icon33.active::before,
  .nav-icon33:hover::before {
    background-image: url("../Icons/BOSS_Vertikale_Vakuummaschinen_hover.svg");
  }
  .nav-icon34::before {
    display: block;
    background-image: url("../Icons/BOSS_AutomatischeVakuummaschinen.svg");
  }
  .nav-icon34.active::before,
  .nav-icon34:hover::before {
    background-image: url("../Icons/BOSS_AutomatischeVakuummaschinen_hover.svg");
  }
  .nav-icon37::before {
    display: block;
    background-image: url("../Icons/BV_VollautomatischeSiegler.svg");
  }
  .nav-icon37.active::before,
  .nav-icon37:hover::before {
    background-image: url("../Icons/BV_VollautomatischeSiegler_hover.svg");
  }
  .nav-icon38::before {
    display: block;
    background-image: url("../Icons/BV_HalbautomatischeSiegler.svg");
  }
  .nav-icon38.active::before,
  .nav-icon38:hover::before {
    background-image: url("../Icons/BV_HalbautomatischeSiegler_hover.svg");
  }
  .nav-icon39::before {
    display: block;
    background-image: url("../Icons/BV_Handsiegler.svg");
  }
  .nav-icon39.active::before,
  .nav-icon39:hover::before {
    background-image: url("../Icons/BV_Handsiegler_hover.svg");
  }
  .nav-icon40::before {
    display: block;
    background-image: url("../Icons/BOSS_Trockner.svg");
  }
  .nav-icon40.active::before,
  .nav-icon40:hover::before {
    background-image: url("../Icons/BOSS_Trockner_hover.svg");
  }
  .nav-icon346::before {
    display: block;
    background-image: url("../Icons/BOSS_Anwendungsfelder.svg");
  }
  .nav-icon346.active::before,
  .nav-icon346:hover::before {
    background-image: url("../Icons/BOSS_Anwendungsfelder_hover.svg");
  }
  .nav-icon355::before {
    display: block;
    background-image: url("../Icons/BOSS_Beutel.svg");
  }
  .nav-icon355.active::before,
  .nav-icon355:hover::before {
    background-image: url("../Icons/BOSS_Beutel_hover.svg");
  }
  .nav-icon356::before {
    display: block;
    background-image: url("../Icons/BOSS_MaschinenTechnologie.svg");
  }
  .nav-icon356.active::before,
  .nav-icon356:hover::before {
    background-image: url("../Icons/BOSS_MaschinenTechnologie_hover.svg");
  }
  .nav-icon357::before {
    display: block;
    background-image: url("../Icons/BOSS_Service.svg");
  }
  .nav-icon357.active::before,
  .nav-icon357:hover::before {
    background-image: url("../Icons/BOSS_Service_hover.svg");
  }
  .nav-icon358::before {
    display: block;
    background-image: url("../Icons/BOSS_Vakuumprozess.svg");
  }
  .nav-icon358.active::before,
  .nav-icon358:hover::before {
    background-image: url("../Icons/BOSS_Vakuumprozess_hover.svg");
  }
}

.headerLanding {
  .supheader {
    font-family: $font-family-sans-serif;
    font-size: 2rem;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 5rem;
    }
    font-weight: 900;
  }
  h1 {
    font-family: $font-family-sans-serif;
    font-size: 3rem;
    @include media-breakpoint-up(sm) {
      font-size: 4rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 7rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 8rem;
    }
    font-weight: 900;
    line-height: .9;
  }
  .subheader {
    font-size: 1.5rem;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 3rem;
    }
    font-weight: 500;
  }
  .lead {
    font-size: 1.3rem;
    p {
      margin-bottom: .5rem;
    }
  }
}

.teaserLanding {
  img {
    &.img-teaser-landing {
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(md) {
        max-width: none;
      }
    }
  }
}

.testimonialLanding {
  h1 {
    position: relative;
    width: fit-content;
    padding: 0;
    font-family: $font-family-sans-serif;
    font-size: 2rem;
    &::before,
    &::after {
      position: relative;
      width: 40px;
      height: 40px;
      content: "";
      background-repeat: no-repeat;
    }
    &::before {
      top: 25%;
      left: -20px;
      background-image: url("../Icons/BOSS_quote-start.svg");
    }
    &::after {
      right: -20px;
      bottom: 25%;
      background-image: url("../Icons/BOSS_quote-end.svg");
    }
    @include media-breakpoint-up(sm) {
      &::before,
      &::after {
        width: 50px;
        height: 50px;
      }
      &::before {
        left: -30px;
      }
      &::after {
        right: -30px;
      }
    }
    @include media-breakpoint-up(md) {
      font-size: 3rem;
      &::before,
      &::after {
        width: 60px;
        height: 60px;
      }
      &::before {
        left: -40px;
      }
      &::after {
        right: -40px;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
      &::before,
      &::after {
        width: 70px;
        height: 70px;
      }
      &::before {
        left: -50px;
      }
      &::after {
        right: -50px;
      }
    }
    @include media-breakpoint-up(xl) {
      font-size: 5rem;
      &::before,
      &::after {
        width: 80px;
        height: 80px;
      }
      &::before {
        left: -60px;
      }
      &::after {
        right: -60px;
      }
    }
    font-weight: 900;
    line-height: .9;
    background-image: url("../Icons/BOSS_quote-start.svg"), url("../Icons/BOSS_quote-end.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: left 70%, right 30%;
    background-size: 10%, 10%;
  }
  strong {
    font-weight: 900;
  }
  .testimonial-info {
    margin-top: 2rem;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 6rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 8rem;
    }
  }
}

.content {
  padding: 1rem;
  @include media-breakpoint-up(lg) {
    max-width: 555px;
    padding: 3rem 4rem 1rem;
  }
  @include media-breakpoint-up(xl) {
  }
}

.carousel-control-next,
.carousel-control-prev {
  align-items: start;
  @include media-breakpoint-up(lg) {
    align-items: center;
  }
}

.carousel {
  .carousel-control-prev {
    justify-content: flex-start;
    opacity: 1;
    span {
      background-image: url("../Icons/BOSS_Pfeil_links.svg");
    }
  }
  .carousel-control-next {
    justify-content: flex-end;
    opacity: 1;
    span {
      background-image: url("../Icons/BOSS_Pfeil_rechts.svg");
    }
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
    @include media-breakpoint-up(lg) {
      width: 50px;
      height: 50px;
    }
    padding: 20px;
    color: $gray-medium;
    background-color: rgba($gray, .7);
    background-origin: content-box;
    background-size: 30px 30px;
  }
}

.slideshow,
.slidecontent,
.carousel-top {
  .carousel-caption {
    position: initial;
    z-index: 1;
    hyphens: none;
    text-align: left;
    overflow-wrap: break-word;
  }
  .carousel-indicators {
    position: initial;
    margin: 0;
    li {
      width: 100%;
      height: 8px;
      margin: 0 6px 0 0;
      margin: 0;
      background-color: $primary;
      border: 0;
      opacity: 0;
      transition: opacity .3s;
      &.active {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        opacity: 1;
      }
    }
  }
}
.slidecontent {
  .carousel-caption {
    position: absolute;
  }
}
.slideshow {
  .carousel-caption {
    position: absolute;
    top: 15%;
    right: auto;
    bottom: auto;
    left: auto;
    h1,
    h2,
    h3,
    h4,
    h5 {
      padding: 5px 1.5rem;
      background-color: $white;
    }
  }
  &.carousel .carousel-control-next-icon {
    background-color: $white;
    background-image: url("../Icons/BOSS_Pfeil_rechts_blau.svg");
  }
  &.carousel .carousel-control-prev-icon {
    background-color: $white;
    background-image: url("../Icons/BOSS_Pfeil_links_blau.svg");
  }
}

.knowledge,
.teaser-carousel,
.menu-subpages {
  p {
    hyphens: none;
  }
  .carousel-indicators {
    bottom: -35px;
    bottom: -50px;
    z-index: 8;
    li {
      width: 12px;
      height: 12px;
      background-color: $gray;
      border-radius: 50%;
      -webkit-box-shadow: inset 0 0 0 1px transparent;
      box-shadow: inset 0 0 0 1px transparent;
      opacity: 1;
      -webkit-transition: -webkit-box-shadow .2s ease;
      -o-transition: box-shadow .2s ease;
      transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
      transition: -webkit-box-shadow .2s ease;
      &:hover {
        -webkit-box-shadow: inset 0 0 0 1px $tourquoise;
        box-shadow: inset 0 0 0 1px $tourquoise;
        -webkit-transition: -webkit-box-shadow .2s ease;
        -o-transition: box-shadow .2s ease;
        transition: -webkit-box-shadow .2s ease;
        transition: box-shadow .2s ease;
        transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
      }
      &.active {
        background-color: $tourquoise;
      }
    }
  }
}
.news-start .item-start,
.teaser-carousel {
  .flex-equal {
    flex: 1 1 auto;
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
}

.teaser-pages {
  .imagelink {
    background-color: $gray;
  }
}

.teaser-kasten {
  margin-top: -3rem;
  margin-bottom: -3rem;
  .flex-equal {
    hyphens: none;
    overflow-wrap: break-word;
    h2 {
      margin: .2rem;
      font-size: 1rem;
      @include media-breakpoint-up(sm) {
        margin-top: .8rem;
        font-size: 1.5rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: .4rem;
        font-size: 2rem;
      }
      @include media-breakpoint-up(xl) {
        margin: 0;
        font-size: 2.5rem;
      }
    }
    &:hover {
      background-color: $tourquoise !important;
      transition: background-color .2s ease-in-out;
    }
  }
}

.menu-subpages,
.teaser-bildheadtextlink {
  // h2 {
  //   font-size: 3.5rem;

  //   @include media-breakpoint-down(lg) {
  //     font-size: 3rem;
  //   }
  //   @include media-breakpoint-down(md) {
  //     font-size: 2.5rem;
  //   }
  //   @include media-breakpoint-down(sm) {
  //     font-size: 2rem;
  //   }
  //   @include media-breakpoint-down(xs) {
  //     font-size: 1.75rem;
  //   }
  // }
  p {
    hyphens: none;
  }
  .rubrik {
    @include media-breakpoint-up(lg) {
      max-width: 500px;
    }
    .branchenbild {
      min-width: 367px;
      @include media-breakpoint-up(md) {
        min-width: 0;
      }
    }
  }
  .flex-equal {
    flex: 1 1 auto;
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
}

.page-header {

  .flex-equal {
    @include media-breakpoint-down(lg) {
      flex: 100%;
    }
  }

  .content {
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }
}

.teaser-headlines {
  .flex-equal {
    flex: 1 1 auto;
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
  .content {
    padding: 2rem 1rem 2rem 0;
    @include media-breakpoint-up(lg) {
      max-width: 666px;
    }
    @include media-breakpoint-up(md) {
      padding: 0;
    }
    p {
      max-width: 95%;
      @include media-breakpoint-up(lg) {
        max-width: 388px;
      }
    }
  }
}

.testimonial {
  .sentence {
    hyphens: none;
    strong {
      // font-size: 2rem;
    }
    hr {
      border-top-width: 1px;
    }
    h2 {
      // font-size: 1.5rem;
      // @include media-breakpoint-down(lg) {
      //   font-size: 1rem;
      // }
    }
    p {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1;
      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.2;
      }
    }
    p.quelle {
      font-size: 1rem;
      font-weight: 200;
    }
  }
  .flex-equal {
    flex: 1 1 auto;
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
}

.teaser-pages a,
.productgrid-item {
  position: relative;
  overflow: hidden;
  color: $blue;
  &.js-comparethis,
  &:hover {
    text-decoration: none;
    img {
      transform: scale(1.075);
    }
    .productgrid-data {
      transition: transform .25s ease;
      transform: translate(0, 0);
    }
  }
}
.teaser-pages-title,
.row-productgrid-title {
  position: absolute;
  right: 0;
  bottom: 12px;
  left: 0;
  font-family: $font-family-condensed;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.materialgrid-item {
  position: relative;
  overflow: hidden;
  hyphens: manual;
  a {
    color: $white;
    text-decoration: none;
  }
  .pfeil {
    &::after {
      background-image: url('data:image/svg+xml;charset=UTF-8, <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 15"><style></style><g fill="none" stroke="%23ffffff" stroke-linecap="round"><path d="M2.83 7.5h28.84"/><path stroke-linejoin="round" d="M25.31 13.29l6.36-5.79-6.36-5.79"/></g></svg>');
    }
  }
  .materialgrid-data {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    color: $white;
    background-color: rgba($gray-darker, .9);
    transition: transform .25s ease;
    transform: translate(0, 600px);
    .materialgrid-data-title {
      margin: 1rem 0 2rem;
      font-family: $font-family-condensed;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 2.5rem;
      color: $white;
      text-transform: uppercase;
    }
    .materialgrid-data-description {
      margin-bottom: 2rem;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $white;
    }
  }
  &:hover {
    .materialgrid-data {
      transform: translate(0, 0);
    }
  }
  .row-materialgrid-title  {
    position: absolute;
    top: calc(50% + 1.5rem);
    left: 50%;
    padding: 5px 1.5rem;
    font-family: $font-family-condensed;
    font-size: 3rem;
    @include media-breakpoint-up(sm) {
      font-size: 1.4rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: $white;
    transform: translate(-50%, -50%);
  }
}

.productgrid-data {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  color: $white;
  background-color: rgba($gray-darker, .9);
  transition: transform .25s ease;
  transform: translate(0, 600px);
  a,
  a.pdfdownload {
    color: $white;
    text-decoration: none;
  }

  .pfeil {
    float: right;
    &::after {
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 1rem;
      line-height: 0;
      vertical-align: middle;
      content: "";
      background-image: url('data:image/svg+xml;charset=UTF-8, <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 15"><style></style><g fill="none" stroke="%23ffffff" stroke-linecap="round"><path d="M2.83 7.5h28.84"/><path stroke-linejoin="round" d="M25.31 13.29l6.36-5.79-6.36-5.79"/></g></svg>');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 30px 13px;
      -webkit-transition: margin .3s ease;
      -o-transition: margin .3s ease;
      transition: margin .3s ease;
    }
    &:hover::after {
      margin-left: 1.5rem;
      color: $white;
      text-decoration: none;
      -webkit-transition: margin .3s ease;
      -o-transition: margin .3s ease;
      transition: margin .3s ease;
    }
  }
}

a.productgrid-data:hover {
  color: $white;
  text-decoration: none;
}

.productgrid-data-title {
  margin-top: 1rem;
  font-family: $font-family-condensed;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
}
.productgrid-data-subtitle {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
}
.productgrid-data-bottom {
  font-size: 1rem;
  line-height: 1.5rem;
}

.productgrid-item-filler {
  h1,
  h2,
  h3,
  h4,
  h5 {
    hyphens: auto;
  }
  background-color: $gray-light;
  outline: 0 solid $gray;
}

.produkt,
.material {
  .hintergrundbild {
    background-repeat: no-repeat;
    background-position: center center;
    background-clip: padding-box;
    background-origin: content-box;
    &.aussen {
      min-height: 0;
      background-size: 0 0;
    }
    &.innen {
      min-height: 30vh;
      background-position: center left;
      background-size: contain;
    }

    @include media-breakpoint-up(md) {
      &.innen {
        min-height: 40vh;
      }
    }
    @include media-breakpoint-up(lg) {
      &.aussen {
        min-height: 50vh;
        background-size: contain;
      }
      &.innen {
        min-height: 0;
        background-size: 0 0;
      }
    }
  }
  .produktinformationen {
    text-shadow: 1px 1px 1px $gray-light;
    h2 small {
      @include media-breakpoint-up(lg) {
        max-width: 50%;
      }
    }
  }
  small {
    display: block;
    font-size: 57%;
  }
  .optionen ul {
    margin-left: -1.5rem;
  }

  .gef {
    display: inline-block;
    margin: 4px;
    &::after {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 0;
      vertical-align: middle;
      content: "";
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 50px 50px;
    }
  }
  .gef.gastronomie::after {
    background-image: url("../Icons/BOSS_Essen_menu.svg");
  }
  .gef.fleisch::after {
    background-image: url("../Icons/BOSS_Icon_Fleisch_menu.svg");
  }
  .gef.fisch::after {
    background-image: url("../Icons/BOSS_Icon_Fisch_menu.svg");
  }
  .gef.gemuese::after {
    background-image: url("../Icons/BOSS_Icon_Gemuese_menu.svg");
  }
  .gef.direkt::after {
    background-image: url("../Icons/BOSS_Icon_Direktvermarktung_menu.svg");
  }
  .gef.einzelhandel::after {
    background-image: url("../Icons/BOSS_Icon_Einzelhandel_menu.svg");
  }
  .gef.elektronik::after {
    background-image: url("../Icons/BOSS_Icon_Elektronik_menu.svg");
  }
  .gef.lieferung::after {
    background-image: url("../Icons/BOSS_Icon_Lieferung_menu.svg");
  }
  .gef.spargel::after {
    background-image: url("../Icons/BOSS_Icon_Spargel_menu.svg");
  }
  .gef.mikrowelle::after {
    background-image: url("../Icons/BS_Icon_Mikrowelle_menu.svg");
  }
  .gef.kochtopf::after {
    background-image: url("../Icons/BS_Icon_Kochtopf_menu.svg");
  }
  .gef.kochmuetze::after {
    background-image: url("../Icons/BS_Icon_Kochmuetze_menu.svg");
  }
  .gef.eis::after {
    background-image: url("../Icons/BS_Icon_Eis_menu.svg");
  }
  .gef.backofen::after {
    background-image: url("../Icons/BS_Icon_Backofen_menu.svg");
  }
  .gef.epa::after {
    width: 60px;
    height: 60px;
    background-image: url("../Icons/esd.png");
    background-size: 60px 60px;
  }

  .sgl {
    display: inline-block;
    min-width: 80px;
    height: 100%;
    padding: 0 5px 1rem 0;
    text-align: center;
    &::before {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      content: "";
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 60px 60px;
    }
  }
  .sgl.siegelung::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Siegelung.svg");
  }
  .sgl.hermetisch::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Hermetisch.svg");
  }
  .sgl.skin::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Skin.svg");
  }
  .sgl.skindeep::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Skindeep.svg");
  }
  .sgl.skinplus::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Skinplus.svg");
  }
  .sgl.deepskin::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Deepskin.svg");
  }
  .sgl.gasspuelung::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Gasspuelung.svg");
  }
  .sgl.vakuumgas::before {
    background-image: url("../Icons/Siegelarten/BS_Siegel_Vakuumgas.svg");
  }

  @media (min-width: 1400px) {
    .offset-lg-1 {
      margin-left: 0 !important;
    }
    .offset-lg-3 {
      margin-left: 33.333333% !important;
    }
  }
}

.produkt .gef.epa {
  position: relative;
  top: -8px;
}

.productgrid-item .epa::after {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 0;
  vertical-align: middle;
  content: "";
  background-image: url("../Icons/esd.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px 40px;
}

.schalenmasse,
.schalenformate,
.siegelplatten {
  .icon {
    display: inline-block;
    margin: 4px;
  }
}

.schalenmasse {
  .icon165x119::before,
  .icon187x137::before,
  .icon190x144::before,
  .icon190x190::before,
  .icon215x145::before,
  .icon227x178::before,
  .icon230x145::before,
  .icon234x184::before,
  .icon238x218::before,
  .icon242x150::before,
  .icon265x161::before,
  .icon275x175::before,
  .icon280x235::before,
  .icon304x120::before,
  .icon304x265::before,
  .icon310x240::before,
  .icon325x265::before,
  .icon356x280::before,
  .icon365x265::before {
    display: block;
    width: 80px;
    height: 60px;
    margin: 0 auto;
    content: "";
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80px 60px;
  }
  .icon165x119::before {
    background-image: url("../Icons/Schalenmasse/icon165x119.svg");
  }
  .icon187x137::before {
    background-image: url("../Icons/Schalenmasse/icon187x137.svg");
  }
  .icon190x144::before {
    background-image: url("../Icons/Schalenmasse/icon190x144.svg");
  }
  .icon190x190::before {
    background-image: url("../Icons/Schalenmasse/icon190x190.svg");
  }
  .icon215x145::before {
    background-image: url("../Icons/Schalenmasse/icon215x145.svg");
  }
  .icon227x178::before {
    background-image: url("../Icons/Schalenmasse/icon227x178.svg");
  }
  .icon230x145::before {
    background-image: url("../Icons/Schalenmasse/icon230x145.svg");
  }
  .icon234x184::before {
    background-image: url("../Icons/Schalenmasse/icon234x184.svg");
  }
  .icon238x218::before {
    background-image: url("../Icons/Schalenmasse/icon238x218.svg");
  }
  .icon242x150::before {
    background-image: url("../Icons/Schalenmasse/icon242x150.svg");
  }
  .icon265x161::before {
    background-image: url("../Icons/Schalenmasse/icon265x161.svg");
  }
  .icon275x175::before {
    background-image: url("../Icons/Schalenmasse/icon275x175.svg");
  }
  .icon280x235::before {
    background-image: url("../Icons/Schalenmasse/icon280x235.svg");
  }
  .icon304x120::before {
    background-image: url("../Icons/Schalenmasse/icon304x120.svg");
  }
  .icon304x265::before {
    background-image: url("../Icons/Schalenmasse/icon304x265.svg");
  }
  .icon310x240::before {
    background-image: url("../Icons/Schalenmasse/icon310x240.svg");
  }
  .icon325x265::before {
    background-image: url("../Icons/Schalenmasse/icon325x265.svg");
  }
  .icon356x280::before {
    background-image: url("../Icons/Schalenmasse/icon356x280.svg");
  }
  .icon365x265::before {
    background-image: url("../Icons/Schalenmasse/icon365x265.svg");
  }
}

.schalenformate,
.siegelplatten {
  // e = rechtEckig
  .icon1fache::before,
  .icon3fache::before,

  // q = Quadratisch
  .icon1fachq::before,

  // k = Kreisrund
  .icon1fachk::before,

  // s = Senkrecht
  .icon2fachs::before,
  .icon3fachs::before,
  .icon4fachs::before,

  // d = Dreieckig
  .icon3fachd::before,

  // w = Waagerecht
  .icon2fachw::before,
  .icon4fachw::before,
  .icon6fachw::before,
  .icon8fachw::before,

  // r = Rund
  .icon1fachr::before,
  .icon2fachr::before,
  .icon3fachr::before,
  .icon6fachr::before {
    display: block;
    width: 60px;
    height: 40px;
    margin: 0 auto;
    content: "";
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 60px 40px;
  }
}

.schalenformate {
  .icon1fache::before {
    background-image: url("../Icons/Schalenformate/icon1fache.svg");
  }
  .icon3fache::before {
    background-image: url("../Icons/Schalenformate/icon3fache.svg");
  }

  .icon1fachq::before {
    background-image: url("../Icons/Schalenformate/icon1fachq.svg");
  }

  .icon1fachk::before {
    background-image: url("../Icons/Schalenformate/icon1fachk.svg");
  }

  .icon2fachs::before {
    background-image: url("../Icons/Schalenformate/icon2fachs.svg");
  }

  .icon3fachd::before {
    background-image: url("../Icons/Schalenformate/icon3fachd.svg");
  }
}

.siegelplatten {
  .icon1fache::before {
    background-image: url("../Icons/Siegelplatten/icon1fache.svg");
  }

  .icon2fachs::before {
    background-image: url("../Icons/Siegelplatten/icon2fachs.svg");
  }
  .icon3fachs::before {
    background-image: url("../Icons/Siegelplatten/icon3fachs.svg");
  }
  .icon4fachs::before {
    background-image: url("../Icons/Siegelplatten/icon4fachs.svg");
  }

  .icon2fachw::before {
    background-image: url("../Icons/Siegelplatten/icon2fachw.svg");
  }
  .icon4fachw::before {
    background-image: url("../Icons/Siegelplatten/icon4fachw.svg");
  }
  .icon6fachw::before {
    background-image: url("../Icons/Siegelplatten/icon6fachw.svg");
  }
  .icon8fachw::before {
    background-image: url("../Icons/Siegelplatten/icon8fachw.svg");
  }

  .icon1fachr::before {
    background-image: url("../Icons/Siegelplatten/icon1fachr.svg");
  }
  .icon2fachr::before {
    background-image: url("../Icons/Siegelplatten/icon2fachr.svg");
  }
  .icon3fachr::before {
    background-image: url("../Icons/Siegelplatten/icon3fachr.svg");
  }
  .icon6fachr::before {
    background-image: url("../Icons/Siegelplatten/icon6fachr.svg");
  }
  th {
    width: 18%;
    border-top: 0 !important;
    border-bottom: 1px solid $secondary !important;
  }
  td {
    width: 18%;
    border-bottom: 1px solid $secondary;
  }
  th:first-child,
  td:first-child {
    width: 28%;
  }
  th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }
}

.btn-boss {
  display: inline-block;
  padding: .75rem;
  color: $white;
  text-align: left;
  text-transform: uppercase;
  background-color: $tourquoise;
  border: none;
  border-radius: 0;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, .25);
  &::after {
    display: inline-block;
    width: 32px;
    height: 14px;
    margin-left: 10px;
    line-height: 0;
    vertical-align: middle;
    content: "";
    background-color: $tourquoise;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 15"><style></style><g fill="none" stroke="%23fff" stroke-linecap="round"><path d="M2.83 7.5h28.84"/><path stroke-linejoin="round" d="M25.31 13.29l6.36-5.79-6.36-5.79"/></g></svg>');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: margin .3s ease;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:hover::after {
    margin-left: 15px;
    transition: margin .3s ease;
  }
  &.orange {
    background-color: $orange;
    &::after {
      background-color: $orange;
    }
  }
  &.blue {
    background-color: $blue;
    &::after {
      background-color: $blue;
    }
  }
  &.gray-dark {
    background-color: $gray-dark;
    &::after {
      background-color: $gray-dark;
    }
  }
  &.black {
    background-color: $black;
    &::after {
      background-color: $black;
    }
  }
  &.gray {
    color: $black;
    background-color: $gray;
    &::after {
      background-color: $gray;
      background-image: url('data:image/svg+xml;charset=UTF-8, <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 15"><style></style><g fill="none" stroke="%23000" stroke-linecap="round"><path d="M2.83 7.5h28.84"/><path stroke-linejoin="round" d="M25.31 13.29l6.36-5.79-6.36-5.79"/></g></svg>');
    }
  }
}

.videobild {
  position: relative;
  .texte {
    position: absolute;
    top: 4rem;
    left: 4rem;
    max-width: 555px;
    background: $white;
    background: rgba($white, .6);
  }
}

.downloads {
  .card-body {
    padding: 0;

    a {
      display: block;
      line-height: 40px;
      color: $primary;

      img {
        width: auto;
        &.mr-2 {
          margin-right: 0;
        }
      }
    }

    li {
      padding-left: 3rem;
      a {
        font-weight: 700;
      }
    }
  }
  .card {
    border: none;
    border-radius: 0;
    .card-header,
    .card-header:first-child {
      color: $white;
      background-color: $primary;
      border: none;
      border-bottom: 1px solid $gray;
      border-radius: 0;
    }
    &:first-of-type {
      border-bottom: 1px solid $gray;
    }
    &:last-of-type {
      border-bottom: 1px solid $primary;
    }
    &:last-child {
      margin-bottom: 2rem;
    }
    .card-header {
      padding: .75rem .5rem;
      @include media-breakpoint-up(sm) {
        padding: .75rem 1.25rem;
      }

      a {
        color: $white;
        text-decoration: none;
      }
    }

    .card-header span.before::before {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 1rem;
      line-height: 0;
      vertical-align: top;
      content: "";
      background-image: url("../Icons/BOSS_Icon_Ordner_gross.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 30px 30px;
    }
    .card-header span.after::after {
      display: inline-block;
      float: right;
      width: 30px;
      height: 30px;
      @include media-breakpoint-up(sm) {
        margin-right: 33%;
      }
      @include media-breakpoint-up(md) {
        margin-right: 49.1%;
      }
      line-height: 0;
      vertical-align: top;
      content: "";
      background-image: url("../Icons/BOSS_Pfeil_unten_weiss.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 30px 30px;
      transition: all .3s ease;
    }
    .card-header .collapsed span.after::after {
      background-image: url("../Icons/BOSS_Pfeil_rechts_ws.svg");
      transition: all .3s ease;
    }
    a {
      vertical-align: bottom;
    }
    .card {
      .card-header {
        a {
          color: $primary;
        }
      }
      .card-body {
        li {
          padding-bottom: .5rem;
          padding-left: 3rem;
          @include media-breakpoint-up(sm) {
            padding-left: 6rem;
          }
          border-bottom: 1px solid $gray;
        }
        li:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }
        img {
          padding-right: 0;
        }
        a {
          display: block;
          font-weight: 700;
          line-height: 40px;
          color: $primary;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .card-header,
      .card-header:first-child {
        padding-left: 2rem;
        @include media-breakpoint-up(sm) {
          padding-left: 4rem;
        }
        color: $primary;
        background-color: $gray-light;
        border-top: 1px solid $primary;
        .card-header {
          border-bottom: 1px solid $primary;
        }
      }
      .card-header span.before::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: .5rem;
        line-height: 0;
        vertical-align: middle;
        content: "";
        background-image: url("../Icons/BOSS_Icon_Ordner_klein.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 20px 20px;
      }
      a {
        vertical-align: middle;
      }
    }
  }
}

.knowledge {
  .card-body {
    padding: 0 0 0 2.5rem;
    a {
      display: block;
      line-height: 40px;
      color: $primary;
      text-align: left;
      background-color: $white;
      &.collapsed {
        color: $primary;
      }
    }
    li {
      padding-left: 3rem;
      a {
        font-weight: 700;
      }
    }
  }
  .card {
    border: none;
    border-radius: 0;
    .card-header {
      padding: .75rem .5rem;
      color: $white;
      background-color: $white;
      border: none;
      border-radius: 0;
      @include media-breakpoint-up(sm) {
        padding: .75rem 0;
      }
      button {
        width: 100%;
        font-weight: 500;
        text-align: left;
        text-decoration: none;
        background-color: $tourquoise;
        border: 0;
        border-radius: 0;
        transition: background-color .3s ease;
        &.collapsed {
          background-color: $white;
        }
      }
    }
    .card-header button::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      line-height: 0;
      vertical-align: top;
      content: "";
      background-image: url("../Icons/BOSS_Pfeil_unten_blau.svg");
      background-repeat: no-repeat;
      transition: background-image .3s ease;
    }
    .card-header button.collapsed::before {
      background-image: url("../Icons/BOSS_Pfeil_rechts_blau.svg");
      transition: background-image .3s ease;
    }
    .card-body {
      a.topic {
        color: $tourquoise;
      }
      a.topic.collapsed {
        color: $primary;
      }
    }
  }
  .carousel-indicators {
    bottom: -15px;
  }
}

.newsletter {
  .frame-type-text {
    header {
      text-transform: uppercase;
      h2 {
        margin-left: -.8rem;
        hyphens: none;
        color: $gray-medium;
        overflow-wrap: break-word;
      }
      h3 {
        margin-top: -2rem;
        // font-size: 2.1rem;
        @include media-breakpoint-up(sm) {
          margin-top: -2.5rem;
        }
        @include media-breakpoint-up(md) {
          margin-top: -4rem;
        }
        @include media-breakpoint-up(xl) {
          margin-top: -5.3rem;
        }
      }
    }
  }
  form {
    margin-top: 2rem;
    color: $gray-dark;
    .lead {
      color: $primary;
    }
    a {
      color: $primary;
    }
    input[type="checkbox"] {
    }
    .form-control {
      color: $primary;
      border: 0;
      border-radius: 0;
    }
    .btn {
      width: 100%;
      border-radius: 0;
    }
  }
}

.up {
  a {
    display: inline-block;
    margin-top: 10px;
  }
  &:hover a {
    -moz-animation: bounce 1s 3;
    -webkit-animation: bounce 1s 3;
    animation: bounce 1s 3;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.bgimage {
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  &-header-landing {
    position: relative;
    // right: 50%;
    // left: 50%;
    width: 100vw;
    max-width: 100vw;
    max-width: 1400px;
    // margin-right: -50vw;
    // margin-left: -50vw;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: top center;
    background-size: 100% 50%;
    background-size: contain;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      background-size: 100% 55%;
      background-size: contain;
    }
    @include media-breakpoint-up(lg) {
      background-size: 100% 60%;
      background-size: contain;
    }
    @include media-breakpoint-up(xl) {
    }
  }
  &-testimonial-landing {
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 25%;
    @include media-breakpoint-up(sm) {
      min-height: 250px;
    }
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      min-height: 400px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 500px;
    }
  }
}

.tooltip {
  pointer-events: none;
}

.youtube-channel-link {
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 19;
  max-width: 300px;
  color: $blue;
  text-align: right;
}

.customplaybutton {
  max-width: 180px !important;
}

.customplaybutton polygon {
  fill: $tourquoise !important;
}

.customplaybutton circle {
  fill: rgba($white, .8) !important;
  stroke: $white !important;
  stroke-width: 2;
}

.small .coverimage svg.customplaybutton {
  position: relative;
  top: 50%;
  float: none;
  width: 14%;
  min-width: 70px;
  max-width: 90px;
  height: auto;
  margin: auto;
  transform: translateY(-50%);
}

.coverimage .youtubevideocovertext {
  top: 10%;
  bottom: auto;

  h2,
  p {
    padding: 0 !important;
    color: $blue;
    background-color: transparent !important;
  }
}

.frame-type-youtubevideo_pi1 {
  h5 {
    min-height: 2.5rem /* 2*font-size */
  }
}

img.lazyload {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

img.lazyload.loaded {
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -o-filter: blur(0);
  -ms-filter: blur(0);
  filter: blur(0);
  transition: all .3s ease;
}

a.pdfdownload {
  color: $blue;
}

.tt_address_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .vcard {
    width: 25%;
    padding: 0 1rem;
    text-align: center;

    @include media-breakpoint-down(lg) {
      width: 33.333333%;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    figure {
      @include media-breakpoint-down(lg) {
        margin-top: 1rem;
      }

      @include media-breakpoint-down(sm) {
        float: right;
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        float: none;
        width: 100%;
      }
    }
  }

  .rounded {
    padding: 0;
    border-radius: 50% !important;

    @include media-breakpoint-down(sm) {
      width: 75%;
    }
  }
}

.zentrale {
  .tt_address_list {
    display: block;

    .vcard {
      width: auto;
      text-align: left;
    }
  }
}

.weltkarte {
  .karte-kontinent {
    filter: url("#blur");
    &:hover {
      filter: none;
      fill: $blue;
    }
  }
}

.frame-layout-4 .ce-above .ce-gallery {
  margin-bottom: 0;
  .video-embed {
    margin-bottom: -6px;
  }
}

.frame-layout-0 .ce-above .ce-gallery {
  margin-bottom: 0;
}

.contentleft,
.contentright {
  .ce-left .ce-gallery {
    @include media-breakpoint-down(md) {
      float: none;
    }

    @include media-breakpoint-down(sm) {
      float: left;
    }
    @include media-breakpoint-down(xs) {
      float: none;
    }
  }
}

.messe-list-item {
  &::after {
    position: relative;
    bottom: 0;
    display: block;
    width: 15%;
    margin: 1rem 0;
    content: "";
    border-top: 1px solid $blue;
  }

  p {
    margin-bottom: 0;
  }
}

.news-list-view {
  display: flex;
  flex-wrap: wrap;
}
.news-list-item {
  h4 {
    font-size: 1.25rem;
    hyphens: auto;
    @include media-breakpoint-up(lg) {
      hyphens: auto;
    }
    &.notfirst {
      min-height: 48px;
    }
  }
}
.news-list-item:nth-child(3n+1) {
  width: 33%;
  padding: 1rem 1rem 1rem 0;
}
.news-list-item:nth-child(3n-1) {
  width: 33%;
  padding: 1rem .5rem;
}
.news-list-item:nth-child(3n) {
  width: 33%;
  padding: 1rem 0 1rem 1rem;
}
.news-list-item.first{
  position: relative;
  width: 100%;
  padding: 1rem 0;
  .kopfzeile  {
    position: relative;
    width: 100%;
    padding: 0 0 1rem;
    background-color: $white;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 1rem;
      width: 35%;
      padding: 1rem;
      &.rechts {
        right: 5rem;
      }
      &.links {
        left: 5rem;
      }
      &.oben {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        width: 100%;
        padding: 0;
        h4 a {
          color: $blue;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      width: 30%;
    }
    h4 {
      font-size: 1.75rem;
    }
    h4 a {
      color: $tourquoise;
    }
    a {
      color: $blue;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.news-start {
  .item-start {
    h4 {
      hyphens: auto;
    }
  }
}

.news-single {
  .news-text-wrap {
    p:first-child::first-letter {
      float: left;
      font-size: 2.8rem;
      line-height: 2.5rem;
      color: $tourquoise;
      letter-spacing: 5px;
    }
  }
  .news-item-content-elements .frame {
    p:nth-child(2)::first-letter {
      float: left;
      font-size: 2.8rem;
      line-height: 2.5rem;
      color: $tourquoise;
      letter-spacing: 5px;
    }
  }
  .news-img-wrap {
  }
  .pager {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
    }

    .next {
      float: right;
    }
  }
}

.vertriebspartner-container {
  h2 {
    @extend .container;
  }

  .form-group {
    display: flex;
    margin: 0;
  }

  .form-control {
    width: auto;
    font-size: 1rem;
    color: $blue;
    background: url("/typo3conf/ext/hks_template_twbs4/Resources/Public/Icons/BOSS_Pfeil_unten_blau.svg") 96% /1rem no-repeat $white;
    border: none;
    appearance: none;
  }

  label {
    margin-right: .5rem;
    font-size: 1.5rem;
    font-weight: 900;
  }
}

.tx-felogin-pi1 {
  label {
    width: 40%;
    @include media-breakpoint-up(md) {
      width: 20%;
    }
  }
}

.pdfbox {
  margin-top: 42px;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.productpager {
  position: absolute;
  top: -42px;
  @include media-breakpoint-up(lg) {
    top: auto;
    bottom: 12px;
  }
  left: calc(50% - 125px);
  z-index: 1;
  width: 250px;
  text-align: center;
  .nav-next {
    float: right;
    width: 85px;
    padding-right: 20px;
    background-image: url("/typo3conf/ext/hks_template_twbs4/Resources/Public/Icons/BOSS_Pfeil_rechts.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.5rem;
  }
  .nav-up {
    width: 40px;
    margin: 0 20px;
    background-image: url("/typo3conf/ext/hks_template_twbs4/Resources/Public/Icons/BOSS_Icon_Uebersicht3.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem;
  }
  .nav-prev {
    float: left;
    width: 85px;
    padding-left: 20px;
    background-image: url("/typo3conf/ext/hks_template_twbs4/Resources/Public/Icons/BOSS_Pfeil_links.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.5rem;
  }
  .pager:not(.nav-prev):first-of-type {
    &.nav-up {
      margin-left: 100px;
    }
  }
  .pager:not(.nav-next):last-of-type {
    &.nav-up {
      margin-right: 100px;
    }
  }
}

.nl_name,
.nl_vorname {
  display: inline-block;
  width: 49%;
}
.nl_vorname {
  margin-right: 2%;
}

.video-embed-item {
  max-width: 100%;
  height: auto;
}
.homevideo {
  margin-bottom: -6px;

  .posvertical {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ce-gallery {
    margin: 0 !important;
  }

  .contentleft {
    display: flex;
    align-items: center;
    justify-content: end;

    .frame-type-textmedia {
      max-width: 500px;
      padding: 0 3rem 0 2rem;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 0 16px;
      }
    }

    @include media-breakpoint-down(md) {
      h2 {
        font-size: 2rem;
        &::after {
          margin: 1.5rem 0;
        }
      }
    }
  }
}

#cookieman-modal {
  @include media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
      max-width: $modal-xl;
    }
  }
  .modal-dialog-centered {
    align-items: flex-end;
  }
  .modal-content,
  .btn {
    border: none;
    border-radius: 0;
  }
}

nav,
.maincontent {
  max-width: 1400px;
}

.anmerkung,
.vermerk {
  font-size: .75rem;
  font-weight: 200;
  color: $gray-dark;
  text-transform: none;
}

.topicheadline {
  margin-top: 3rem;
  margin-bottom: 1.8rem;
  margin-left: 5rem;
  font-size: 2rem;
}

.vertical-center .col-md .frame {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.notification.toast {
  position: fixed;
  top: 90px;
  right: -240px;
  z-index: 1080;
  width: 300px;
  transition: transform .8s;
  backdrop-filter: none;
  &.hide {
    display: flex;
    opacity: 1;
  }
  &.show {
    transform: translateX(-240px);
  }
  .icons:hover {
    cursor: pointer;
  }
  .cta.bg-gray-light::after {
    display: none;
  }
}

//
//  additional Mediaqueries
//

// ≥576px
@include media-breakpoint-up(sm) {
}
// ≥768px
@include media-breakpoint-up(md) {
}
// ≥992px
@include media-breakpoint-up(lg) {
}
// ≥1200px
@include media-breakpoint-up(xl) {
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-only(xl) {
}

// <576px
@include media-breakpoint-down(xs) {
}
// <768px
@include media-breakpoint-down(sm) {
}
// <992
@include media-breakpoint-down(md) {
}
// <1200
@include media-breakpoint-down(lg) {
}

@media print {
}
